import React, {useRef} from "react";
import { useForm, Controller } from 'react-hook-form'
import { Navigate, useNavigate } from 'react-router-dom';
import { isValidPhoneNumber } from "react-phone-number-input";
import Input from 'react-phone-number-input/input';
import useFormPersist from 'react-hook-form-persist'
import styles from './Form.module.scss';
import commonStyles from './Common.module.scss';
import {VALID_EMAIL_RE} from './Constants';

function Form1() {

  const navigate = useNavigate();
  const auctionDate = sessionStorage.getItem("auctionDate");
  const { register, handleSubmit, control, watch, setValue, formState: { errors }} = useForm();
  const formRef = useRef(null);

  useFormPersist('form-data', {watch, setValue}, {
    storage: sessionStorage,
  });

  if (auctionDate === null) {
    return (
      <Navigate to='/' />
    );
  }

  function onSubmit() {
    navigate('/form2');
  }

  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <h1>Tell us about yourself</h1>
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          <input type="hidden" id="nameOfSale" {...register("nameOfSale")}/>
          <input type="hidden" id="auctiondate" value={auctionDate} {...register("auctiondate")}/>
          <fieldset>
            <div className={styles.label}>Full Name</div>
            <div className={styles.fieldRow}>
              <div className={styles.field}>
                <input type="text" id="firstname" placeholder="First Name" {...register("firstname",
                  {
                    required: true,
                    maxLength: 30
                  })}/>
                {errors.firstname && (
                  <div className={styles.error}>First name is required</div>
                )}
              </div>

              <div className={styles.field}>
                <input type="text" id="lastname" placeholder="Last Name" {...register("lastname",
                  {
                    required: true,
                    maxLength: 30
                  })}/>
                {errors.lastname && (
                  <div className={styles.error}>Last name is required</div>
                )}
              </div>
            </div>

            <div className={styles.fieldRow}>
              <div className={styles.field}>
                <div className={styles.label}>Farm/corporation name (if needed)</div>
                <input type="text" id="farm_corp_name" {...register("farm_corp_name",
                  {
                    required: false,
                    maxLength: 60,
                  })}/>
              </div>
              <div className={`${styles.field} ${styles.radioField}`}>
                <div className={styles.label}>Do you have a current policy with us?</div>
                <div className={styles.radioGroup} >
                  <label>
                    <input type="radio" value="Yes" {...register("current_policy_yn", { required: true })} />
                    Yes
                  </label>
                  <label>
                    <input type="radio" value="No" {...register("current_policy_yn", { required: true })} defaultChecked={true} />
                    No
                  </label>
                </div>
              </div>
            </div>

            <div className={styles.label}>Address</div>
            <div className={styles.fieldRow}>
              <div className={styles.field}>
                <input type="text" id="addr1" placeholder="Line 1" {...register("addr1",
                  {
                    required: true,
                    maxLength: 60
                  })}/>
                <div className={styles.error}>
                  {errors.addr1 && "Address is required"}
                </div>
              </div>
            </div>

            <div className={styles.fieldRow}>
              <div className={styles.field}>
                <input type="text" id="addr2" placeholder="Line 2" {...register("addr2",
                  {
                    required: false,
                    maxLength: 60
                  })}/>
              </div>
            </div>

            <div className={styles.fieldRow}>
              <div className={styles.field}>
                <input type="text" id="city" placeholder="City" {...register("city",
                  {
                    required: true,
                    maxLength: 40
                  })}/>
                <div className={styles.error}>
                  {errors.city && "City is required"}
                </div>
              </div>

              <div className={styles.field}>
                <select id="state" placeholder="State" {...register("state", {
                  required: true,
                  validate: (value) => (value !== null)
                })}>
                  <option value=""/>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
                <div className={styles.error}>
                  {errors.state && "State is required"}
                </div>
              </div>

              <div className={styles.field}>
                <input type="text" id="zip" placeholder="Zip Code" {...register("zip",
                  {
                    required: {value: true, message: "Zip code is required"},
                    pattern: {value: /^\d{5}(?:-\d{4})?$/, message: "Invalid zip code"}
                  })}/>
                <div className={styles.error}>
                  {errors.zip && errors.zip.message}
                </div>
              </div>
            </div>

            <div className={styles.label}>Phone Number</div>
            <div className={styles.fieldRow}>
              <div className={styles.field}>
                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    required: {value: true, message: "Phone is required"},
                    validate: (value) => isValidPhoneNumber(value)
                  }}
                  render={({field: {onChange, value}}) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      country="US"
                      id="phone"
                    />
                  )}
                />
                <div className={styles.error}>
                  {errors.phone?.message}
                  {errors.phone?.type === "validate" && "Invalid phone"}
                </div>
              </div>
            </div>

            <div className={styles.label}>Email Address</div>
            <div className={styles.fieldRow}>
              <div className={styles.field}>
                <input type="text" id="email" placeholder="example@address.com" {...register("email",
                  {
                    required: {value: true, message: "Email is required"},
                    pattern: {
                      value: VALID_EMAIL_RE,
                      message: "Invalid email"
                    }
                  })} />
                {errors.email && (
                  <div className={styles.error}>{errors.email.message}</div>
                )}
              </div>
            </div>
          </fieldset>

          <div className={styles.buttonRow}>
            <input className={commonStyles.buttonClear} type="button" value="Back" onClick={() => navigate(-1)}/>
            <input className={commonStyles.buttonFilled} type="submit" value="Next"/>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Form1;
