import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.scss';
import { Header } from './components/Header';
import Footer from "./components/Footer"
import Home from "./components/Home"
import PolicyOptions from "./components/PolicyOptions"
import Form1 from "./components/Form1"
import Form2 from "./components/Form2"
import ContactUs from "./components/ContactUs"
import Terms from "./components/Terms"
import Privacy from "./components/Privacy"

function App() {
  return (
    <Router>
      <Header/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/policy-options" element={<PolicyOptions />} />
        <Route path="/form1" element={<Form1/>} />
        <Route path="/form2" element={<Form2/>} />
        <Route path="/contact" element={<ContactUs/>} />
        <Route path="/terms" element={<Terms/>} />
        <Route path="/privacy" element={<Privacy/>} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
