import React, {useMemo} from "react";
import styles from './Form.module.scss';
import moment from 'moment';

export default function CustomDatePicker({
    value,
    onChange
}) {
  // Generate dynamic days based on the month and year
  const getDaysInMonth = (year, month) => {
    return new Array(new Date(year, month, 0).getDate()).fill(null).map((_, index) => (index + 1).toString());
  };

  const currentDate = useMemo(() => value ? new Date(value) : new Date(), [value]);
  const days = useMemo(() => getDaysInMonth(currentDate.getFullYear(), currentDate.getMonth() + 1), [currentDate]);
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const years = useMemo(() => {
    const currentYear = new Date().getFullYear();
    return new Array(6).fill(null).map((_, index) => (currentYear - index).toString());
  }, []);

  const handleDateChange = (newDateValue) => {
    // Validate the new date before setting it
    if (moment(newDateValue, 'MM/DD/YYYY', true).isValid()) {
      const formattedDate = moment(newDateValue).format('MM/DD/YYYY');
      onChange(formattedDate);
    }
  };

  const handleMonthChange = (e) => {
    const newDate = new Date(currentDate.getFullYear(), e.target.value - 1, currentDate.getDate());
    handleDateChange(newDate);
  };

  const handleDayChange = (e) => {
    const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), e.target.value);
    handleDateChange(newDate);
  };

  const handleYearChange = (e) => {
    const newDate = new Date(e.target.value, currentDate.getMonth(), currentDate.getDate());
    handleDateChange(newDate);
  };

  return (
    <div className={styles.customDatePicker}>
      <select
        value={currentDate.getMonth() + 1}
        onChange={handleMonthChange}
      >
        {months.map((monthName, index) => (
          <option key={monthName} value={index + 1}>
            {monthName}
          </option>
        ))}
      </select>
      <select
        value={currentDate.getDate()}
        onChange={handleDayChange}
      >
        {days.map(day => (
          <option key={day} value={day}>
            {day}
          </option>
        ))}
      </select>
      <select
        value={currentDate.getFullYear()}
        onChange={handleYearChange}
      >
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
}
