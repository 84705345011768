import React, { useRef } from "react";
import {Navigate, useNavigate} from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useFormPersist from 'react-hook-form-persist';
import styles from './Form.module.scss';
import commonStyles from './Common.module.scss';
import policyStyles from './PolicyOptions.module.scss';
import { PolicyTypes } from './Constants';

function PolicyOptions() {
  const navigate = useNavigate();
  const { register, handleSubmit, watch, setValue } = useForm();
  const formRef = useRef(null);
  const policyType = watch('policyType');

  // Persist form data in local storage
  useFormPersist('form-data', { watch, setValue }, {
    storage: sessionStorage,
  });

  const auctionDate = sessionStorage.getItem("auctionDate");
  if (auctionDate === null) {
    return (
      <Navigate to='/' />
    );
  }

  // Handle form submission and navigate to next page
  function onSubmit(data) {
    navigate('/form1');
  }

  const handlePolicySelect = (policyType) => {
    setValue("policyType", policyType);
  };

  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <h1>Choose Your Policy</h1>
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          <div className={policyStyles.policyContainer}>
            <label
              className={`${policyStyles.policyCard} ${policyType === PolicyTypes.ANNUAL ? policyStyles.selected : ''}`}
              onClick={() => handlePolicySelect(PolicyTypes.ANNUAL)}
            >
              <div className={policyStyles.radioCircle}>
                {policyType === PolicyTypes.ANNUAL && <div className={policyStyles.radioInner}></div>}
              </div>
              <div className={policyStyles.policyContent}>
                <div className={policyStyles.policyTitle}>Full Mortality Policy</div>
                <div className={policyStyles.policyRate}>7% Annually</div>
              </div>
              <input
                type="radio"
                value={PolicyTypes.ANNUAL}
                {...register("policyType", { required: true })}
                checked={policyType === PolicyTypes.ANNUAL}
                onChange={() => {}}
                className={policyStyles.hiddenRadio}
              />
            </label>

            <label
              className={`${policyStyles.policyCard} ${policyType === PolicyTypes.THIRTY_DAYS ? policyStyles.selected : ''}`}
              onClick={() => handlePolicySelect(PolicyTypes.THIRTY_DAYS)}
            >
              <div className={policyStyles.radioCircle}>
                {policyType === PolicyTypes.THIRTY_DAYS && <div className={policyStyles.radioInner}></div>}
              </div>
              <div className={policyStyles.policyContent}>
                <div className={policyStyles.policyTitle}>Full Mortality Policy</div>
                <div className={policyStyles.policyRate}>3.5% for 30 days only</div>
                <div className={policyStyles.policyDetail}>Must be paid online at purchase</div>
                <div className={policyStyles.policyDetail}>Fully earned - no refunds</div>
              </div>
              <input
                type="radio"
                value={PolicyTypes.THIRTY_DAYS}
                {...register("policyType", { required: true })}
                checked={policyType === PolicyTypes.THIRTY_DAYS}
                onChange={() => {}}
                className={policyStyles.hiddenRadio}
              />
            </label>
          </div>

          <div className={styles.buttonRow}>
            <input className={commonStyles.buttonClear} type="button" value="Start Over" onClick={() => navigate(-1)}/>
            <input className={commonStyles.buttonFilled} type="submit" value="Next" disabled={!policyType}/>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PolicyOptions;
