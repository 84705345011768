import React, {useEffect} from "react";
import useState from 'react-usestateref'
import { useForm, useFieldArray } from 'react-hook-form'
import { useNavigate } from 'react-router-dom';
import styles from './Form.module.scss';
import commonStyles from './Common.module.scss';
import AnimalsComponent from "./AnimalsComponent";
import { submitApplication } from '../utils/backendService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSpinner, faPlusSquare} from '@fortawesome/free-solid-svg-icons';
import { emitCustomEvent } from 'react-custom-events';
import useFormPersist from 'react-hook-form-persist'
import moment from 'moment';
import {getPremiumPct, ApprovalCode, PolicyTypes, PaymentLink} from './Constants';
const formDataProperty = 'form-data';

const TBD = 'TBD'

function Form2() {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  const getEmptyAnimal = function() {
    return {
      name: "",
      regno: "",
      gender: "",
      breed: "",
      use: "",
      lotno: "",
      purchaseprice: "",
      dob: moment().subtract(90, "days").format('MM/DD/YYYY'),
      creationTime : new Date().getTime(),
      itemState: "FIRST"
    };
  }

  const [, toggleAck, acknowledgedRef] = useState(false)
  const formProps = useForm({ defaultValues: {
    dateOfSale: sessionStorage.getItem("auctionDate"),
    animals: [getEmptyAnimal()]
  }});
  const { register, trigger, handleSubmit, clearErrors, control, watch, setValue, getValues, formState: { isSubmitting,errors }} = formProps;
  const watchedAnimals = watch('animals');
  const fieldArrayProps = useFieldArray({
    name: 'animals',
    control,
  });
  const { fields, append, update } = fieldArrayProps;

  const [, updatedSubmitted, submittedRef] = useState(false);
  const [, setTotalPurchasePrice, totalPurchasePriceRef] = useState();
  const [, setTotalPremium, totalPremiumRef] = useState();
  const [, setPolicyType, policyTypeRef] = useState();
  const navigate = useNavigate();

  useFormPersist(formDataProperty, {watch, setValue}, {
     storage: sessionStorage,
   });

  useEffect(() => {
    const setPriceAndPremium = (value) => {
      let purchasePrice = "$0";
      let premium = "$0";

      if (value.animals.length > 0){
        const currentAnimals = value.animals.filter(a => a.purchaseprice);
        if (currentAnimals.length > 0){
          const containsApprovalCode = currentAnimals.map(o => o.purchaseprice).some(x => (x !== null && x.toLowerCase() === ApprovalCode));
          if (containsApprovalCode){
            purchasePrice = "Verbal approval";
            premium = TBD;
          } else {
            const parsedPrices = currentAnimals.map(o => filterInt(o.purchaseprice));
            const hasInvalidPrice = parsedPrices.some(price => isNaN(price));
            if (hasInvalidPrice) {
              purchasePrice = "Invalid";
              premium = "";
            } else {
              const totalPurchasePrice = parsedPrices.reduce((a, c) => a + c, 0);
              purchasePrice = "$" + totalPurchasePrice.toLocaleString("en-US");
              premium = "$" + numberWithCommas((totalPurchasePrice * getPremiumPct(value.policyType)).toFixed(2));
            }
          }
        }
      }
      setTotalPurchasePrice(purchasePrice);
      setTotalPremium(premium);
      setPolicyType(value.policyType);
    }

    const policyType = getValues()?.policyType;
    if (!policyType) {
      console.log('No policy type found, going home', getValues());
      navigate('/');
    } else {
      console.log('policy type:', policyType);
    }

    setPriceAndPremium(getValues());

    let timeoutId;
    const subscription = watch((value, { name }) => {
      // Handle field updates
      if (name && name.startsWith('animals.')) {
        const animalIndex = parseInt(name.split('.')[1]);
        if (!isNaN(animalIndex) && value.animals.length > animalIndex) {
          update(animalIndex, value.animals[animalIndex]);
        }
      }

      // Debounce the price/premium calculation
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setPriceAndPremium(value);
      }, 500);
    });

    return () => {
      subscription.unsubscribe();
      clearTimeout(timeoutId);
    };
  }, [watch, fields, update, setTotalPurchasePrice, setTotalPremium, getValues, setPolicyType, navigate]);

  async function onSubmit(data) {
    try {
      clearErrors("serverside");
      const formIsValid = await trigger();
      if (formIsValid) {
          const result = await submitApplication({
            ...data,
            totalPurchasePrice: totalPurchasePriceRef.current,
            totalPremium: totalPremiumRef.current,
          });
          console.log(result);
          updatedSubmitted(true);
      } else {
        console.log("Errors: ", errors);
      }
    } catch (error) {
      console.error("Submission Error: ", error);
      alert(error);
      //setError("serverside", { message: error.message || "Unknown error occurred" });
    }
  }

  function goHome() {
    updatedSubmitted(false);
    navigate('/');
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function filterInt(value) {
    if (/^([\d,]+)$/.test(value)) {
      return Number(value.replace(',', ''))
    } else {
      return NaN
    }
  }

  async function  handleAddCattle(){
    await trigger();
    emitCustomEvent('submit-open-form');

    if (watchedAnimals.every(a => a.itemState === 'CLOSED')){
      const newAnimal = getEmptyAnimal();
      if (watchedAnimals.length > 0){
        newAnimal.itemState = "NEW";
      }
      append(newAnimal);
    } else {
        console.log("Please close all forms before adding a new one");
    }
  }

  if (submittedRef.current) {
    return policyTypeRef.current === PolicyTypes.ANNUAL ? <>
      <div className={styles.section}>
        <div className={styles.content} style={{minHeight:'500px'}}>
          <div className={styles.fieldRow}>
            <div className={styles.field}>
              <h4>Thank you for your submission. We will get back to you soon.</h4>
            </div>
          </div>
          <div className={styles.buttonRow}>
            <input className={commonStyles.buttonClear} type="button" value="Go Home" onClick={() => goHome()}/>
          </div>
        </div>
      </div>
    </> : <>
      <div className={styles.section}>
        <div className={styles.content} style={{minHeight: '500px'}}>
          <div className={styles.fieldRow}>
            <div className={styles.field}>
              <h4>Thank you for your submission.</h4>
            </div>
          </div>
          <div className={styles.fieldRow}>
            <div className={styles.field}>
              <h4>Your payment due today is <b>{totalPremiumRef.current === TBD ? 'based on verbal approval' : totalPremiumRef.current}</b>.</h4>
            </div>
          </div>
          <div className={styles.fieldRow}>
            <div className={styles.field}>
              <h4><a href={PaymentLink} target={'_blank'} rel={'noreferrer'}>Click here to pay</a></h4>
            </div>
          </div>
          <div className={styles.fieldRow}>
            <div className={styles.field}>
              <div className={styles.paymentNotice}>
                (Payment page will open in a new tab.)
              </div>
            </div>
          </div>
        </div>
      </div>
    </>;
  }

  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1>Tell us about your cattle</h1>
          <div className={`${styles.fieldRow} ${styles.topRow}`}>
            <div className={styles.field}>
              <div className={styles.label}>Name Of Sale</div>
              <input type="text" id="nameOfSale" placeholder="Enter sale name"  {...register(`nameOfSale`,
                {
                  required: {value: true, message: "Sale Name is required"}
                })}/>
              {errors.nameOfSale && (
                <div className={styles.error}>{errors.nameOfSale.message}</div>
              )}
            </div>
            <div className={styles.field}>
              <div className={styles.label}>Date Of Sale</div>
              <select id="dateOfSale"  {...register(`dateOfSale`,
                {
                  required: true
                })}>
                <option
                  value={moment(yesterday).format('MM/DD/yyyy')}>{moment(yesterday).format('MMMM DD, YYYY')}</option>
                <option value={moment(today).format('MM/DD/yyyy')}>{moment(today).format('MMMM DD, YYYY')}</option>
              </select>
              {errors.dateOfSale && (
                <div className={styles.dateOfSale}>{errors.dateOfSale.message}</div>
              )}
            </div>
          </div>
          <div className={`${styles.fieldRow}`}>
            <div className={styles.field}>
              <div className={styles.label}>Comment (optional)</div>
              <input type="text" id="comment" {...register(`comment`,
                {
                  required: false,
                })}/>
            </div>
          </div>
          <AnimalsComponent formProps={formProps} fieldArrayProps={fieldArrayProps}/>
          <div className={styles.addCattleRow}>
            <div
              className={`${styles.addCattleBtn} ${watchedAnimals.some(a => a.itemState !== 'CLOSED') ? styles.disabled : ''}`}
              onClick={handleAddCattle}
            >
              <FontAwesomeIcon icon={faPlusSquare}/>Add Cattle
            </div>
          </div>

          <div className={styles.totalRow}>
            <div className={styles.totalItem}>
              <label>Total purchase price: </label>
              <span className={styles.price}>{totalPurchasePriceRef.current}</span>
              </div>
              <div className={styles.totalItem}>
                <label>Total premium: </label>
                <span className={styles.price}>{totalPremiumRef.current}</span>
              </div>
            </div>


            <div className={styles.acknowledgementRow}>
              <input type="checkbox"
                     id="acknowledgement"
                     onChange={() => toggleAck(!acknowledgedRef.current)}
                     checked={acknowledgedRef.current}
              />
              <p>I understand that by acknowledging this application, each answer given in this application is a
                statement of fact that becomes a part of the policy. I acknowledge that I am aware that if at any time
                it is discovered any of the statements contained in this application are concealed or falsely stated,
                the policy may be modified, rescinded, or declared void from its inception and in accordance with any
                applicable state law.</p>
            </div>
            <div className={styles.buttonRow}>
              <input disabled={isSubmitting} className={commonStyles.buttonClear} type="button" value="Go Back"
                     onClick={() => navigate(-1)}/>
              <button className={commonStyles.buttonFilled} type="submit"
                      disabled={
                        !acknowledgedRef.current
                        || watchedAnimals?.length === 0
                        || totalPurchasePriceRef.current === '$0'
                        || isSubmitting
                      }> {isSubmitting && (<FontAwesomeIcon icon={faSpinner} spin={true}/>)} Submit Application
              </button>
            </div>
        </form>
      </div>
    </div>
);
}

export default Form2;
