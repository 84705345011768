import React from "react";
import AnimalItem from "./AnimalItem";

export default function AnimalsComponent({
    formProps,
    fieldArrayProps,
  }) {

  const { fields, remove } = fieldArrayProps;

  function handleAnimalDelete(animal,index) {
    remove(index);
  }

  return (
      <>
          {fields.map((field, index) => (
              <AnimalItem
                  key={field.id}
                  formProps={formProps}
                  index={index}
                  animal={field}
                  onDelete={handleAnimalDelete}
              />
          ))}
      </>
    );
  }
