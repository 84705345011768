export const VALID_EMAIL_RE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// These must match the values in backend/functions/apply.js
export const ANNUAL_PREMIUM_PCT = 0.07; // 7% annual policy
export const THIRTY_DAY_PREMIUM_PCT = 0.035; // 3.5% for 30-day policy

export const PolicyTypes = {
  ANNUAL: 'annual',
  THIRTY_DAYS: 'thirtyDays',
}

export function getPremiumPct(policyType) {
  return policyType === PolicyTypes.THIRTY_DAYS ? THIRTY_DAY_PREMIUM_PCT : ANNUAL_PREMIUM_PCT;
}

export const ApprovalCode = 'jj2022ok';
export const PaymentLink = 'https://anytime.anddone.com/#/insureyourcattle'
