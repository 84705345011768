// notice we are destructuring the props here. You could change this to
// bring in props instead and then just add `props.` to animal, onEditClick and onDeleteClick:
// export default function AnimalItem(props)

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import styles from './Form.module.scss';
import useState from 'react-usestateref'
import AnimalForm from "./AnimalForm";

export default function AnimalItem({
  animal,
  formProps,
  index,
  onDelete,
}) {
  const [, setItemState, itemStateRef] = useState(animal.itemState || 'CLOSED');
  const { trigger, setValue, formState: { errors }, watch } = formProps;

  // Watch for changes in the specific animal's form state
  const watchedAnimal = watch(`animals[${index}]`);

  async function onEditClick() {
    await trigger();
    if (errors.animals){
      console.log("Not Opening in Edit form as there are errors in opened forms");
    } else {
      setItemState("EDIT");
      setValue(`animals[${index}].itemState`,"EDIT");
    }
  }

  function onDeleteClick() {
    onDelete(animal,index);
  }

  return (
    <>
      {
        ["FIRST","NEW","EDIT"].includes(itemStateRef.current) &&
        <AnimalForm
          animal={watchedAnimal}
          formProps={formProps}
          index={index}
          setItemState = {setItemState}
          itemStateRef = {itemStateRef}
          onDeleteClick = {onDeleteClick} />
      }
      {
        ["CLOSED"].includes(itemStateRef.current) &&
        <div className={styles.animalRollup}>
          <div>
            <label>Lot Number</label>
            <div className={styles.rollupValue}># {watchedAnimal.lotno}</div>
          </div>
          <div>
            <label>Purchase Price</label>
            <div className={styles.rollupValue}>$ {watchedAnimal.purchaseprice}</div>
          </div>
          <div className={styles.actionBtns}>
            <button className={styles.editButton} onClick={onEditClick}><FontAwesomeIcon icon={faEdit}/>Edit</button>
            <button type="button" className={styles.editButton} onClick={onDeleteClick}><FontAwesomeIcon icon={faTrash}/>Delete</button>
          </div>
        </div>
      }

    </>);

}
